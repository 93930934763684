<script>
  import { navigate } from "svelte-routing";
  import "@vtmn/css/dist/index.css";

  import Header from "./components/Header.svelte";
  import Navigation from "./components/Navigation.svelte";
  import { auth } from "./store/auth-store";
</script>

{#if $auth.isAuth}
  <Header />
{/if}
<main>
  <Navigation />
</main>
