<script>
  import { navigate, Router, Route } from "svelte-routing";
  import TranslationForm from "../pages/translationForm/TranslationForm.svelte";
  import Login from "../pages/login/Login.svelte";
  import { auth } from "../store/auth-store";

  let url = "";

  const params = new URLSearchParams(window.location.search);
  const code = params.get("code");
  let tokenIsExpired = false;

  // if ($auth.user?.expiration_date) {
  //   const now = Date.now();
  //   const expirationDate = new Date($auth.user.expiration_date).getTime();
  //   tokenIsExpired = now - expirationDate > 0;
  // }

  $: if (!$auth.isAuth && !code) {
    auth.logout();
    navigate("/login");
  } else if ($auth.isAuth && tokenIsExpired) {
    localStorage.clear();
    //auth.logout();
    navigate("/login");
  } else if ($auth.isAuth && !tokenIsExpired) {
    auth.login($auth.user);
  }
</script>

<Router {url}>
  <div>
    <Route path="/" component={TranslationForm} />
    <Route path="login" component={Login} />
  </div>
</Router>

<style type="text/less">
  @import "./Navigation.less";
</style>
