import { writable } from "svelte/store";
import axios from "../axios-config";

const initialState = {
  isAuth: false,
  user: null,
};

function createAuth() {
  const store = writable(
    JSON.parse(localStorage.getItem("auth")) || initialState
  );
  const { subscribe, set, update } = store;

  store.subscribe((val) => localStorage.setItem("auth", JSON.stringify(val)));

  return {
    subscribe,
    login: (user) =>
      update((state) => {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + user.session_id;
        return { isAuth: true, user };
      }),
    logout: () =>
      update((state) => {
        delete axios.defaults.headers.common["Authorization"];
        return initialState;
      }),
  };
}

export const auth = createAuth();
