<script>
  import { onMount } from "svelte";
  import * as yup from "yup";
  import { Form, Message, isInvalid } from "svelte-yup";
  import axios from "../../axios-config";
  import { navigate } from "svelte-routing";
  import { auth } from "../../store/auth-store";
  import { languages } from "./data";
  import LoadingSpinner from "../../components/LoadingSpinner.svelte";

  let defaultLanguage;
  let shopId;
  let files;
  let translationLanguages = [];

  let message = "";
  let displayMessage = false;
  let alertColor = "";
  let submitted = false;
  let isLoading = false;

  $: bodyToValidate = {
    file: files,
    defaultLanguage,
    shopId,
    translationLanguages
  };

  $: invalid = (name) => {
    if (submitted) {
      return isInvalid(bodySchema, name, bodyToValidate);
    }
    return false;
  };

  let bodySchema = yup.object().shape({
    file: yup.mixed().required('CSV file is required'),
    defaultLanguage: yup.string().required().label("Default Language"),
    shopId: yup.string().matches(/^[0-9]+$/, "Must be only digits").min(4, 'Must be at least 4 digits').required().label("Shop Id"),
    translationLanguages: yup.array().of(yup.string()).required().label("translationLanguages")
  });

  const handleOnSubmit = async () => {
    try {
      displayMessage = false;
      submitted = true;
      const isValid = bodySchema.isValidSync(bodyToValidate);
      if (isValid) {
        isLoading = true;
        const json_object = JSON.stringify({
          source_language: defaultLanguage,
          shop_id: parseInt(shopId),
          target_languages: translationLanguages
        });

        const formData = new FormData();
        formData.append("file", files[0]);
        formData.append("json_object", json_object)

        await axios.post("/import-manager/translation/send-csv", formData, {
          headers: {'Content-Type': `multipart/form-data`}
        });
        displayMessage = true;
        message = "File sent!";
        alertColor = "green";
        isLoading = false;
      }
    } catch (error) {
      displayMessage = true;
      if (error.response.status === 401) {
        auth.logout();
        navigate("/login");
      } else {
        message = `Something went wrong &#128531; : ${error.message}`;
        alertColor = "red";
        isLoading = false;
      }
    }
  };

  onMount(async () => {
    try {
      const {data} = await axios.get("/import-manager/user/check/is-authenticated")
    } catch (error) {
      if (error.response.status === 401) {
        auth.logout();
        navigate("/login");
      } else {
        console.log(error)
      }
    }
  })
</script>

<div class="container">

    <Form
      schema={bodySchema}
      fields={bodyToValidate}
      submitHandler={handleOnSubmit}
      {submitted}
    >
      <div class="form-container">
        <div class="input-wrapper">
          <label class="vtmn-text-input_label" for="file">CSV</label>
          <input
            type="file"
            class:vtmn-text-input--error={invalid("file")}
            class="vtmn-text-input"
            id="file"
            accept=".csv"
            bind:files
          />
          <Message name="file" />
        </div>
        <div class="input-wrapper select-container">
          <label for="defaultLanguage">Source language</label>
          <select
            class:vtmn-text-input--error={invalid("defaultLanguage")}
            class="vtmn-text-input"
            name="defaultLanguage"
            id="defaultLanguage"
            bind:value={defaultLanguage}
          >
            <option value="" disabled selected>Please Choose...</option>
            {#each languages as language, i (i)}
              <option value={language}>{language}</option>
            {/each}
          </select>
          <Message name="defaultLanguage" />
        </div>
        <div class="input-wrapper">
          <label class="vtmn-text-input_label" for="shopId">Shop ID</label>
          <input
            type="text"
            class:vtmn-text-input--error={invalid("shopId")}
            class="vtmn-text-input"
            id="shopId"
            bind:value={shopId}
          />
          <Message name="shopId" />
        </div>
        <div class="input-wrapper">
          <label for="translationLanguages">Languages for translation</label>
          <div class="checkBoxesContainer">
            {#each languages as language, i (i)}
              <div class="checkboxLabel">
                <input
                  type="checkbox"
                  name="translationLanguages"
                  bind:group={translationLanguages}
                  value={language}
                />
                <p>{language}</p>
              </div>
            {/each}
          </div>
        </div>
        {#if isLoading}
        <div class="spinnerContainer">
          <LoadingSpinner />
        </div>
        {:else} 
          <button class="vtmn-btn submit-btn" type="submit">Submit</button>
        {/if}
      </div>
    </Form>

  {#if displayMessage}
    <div class="alert-container {alertColor}">
      <p>{@html message}</p>
    </div>
  {/if}
</div>

<style type="text/less">
  @import "./TranslationForm.less";
</style>
