<script>
  import { onMount } from "svelte";
  import { auth } from "../../store/auth-store";
  import { navigate } from "svelte-routing";

  import axios from "../../axios-config";

  const params = new URLSearchParams(window.location.search);
  const code = params.get("code");

  const redirectLogin = async () => {
    try {
      const { data } = await axios.get("/import-manager/user/login");
      const redirectURI = decodeURI(data.href);

      window.location.href = redirectURI;
    } catch (error) {
      console.log(error);
    }
  };

  onMount(async () => {
    try {
      if (!code) {
        redirectLogin();
      } else {
        const { data } = await axios.post("/import-manager/user/authenticate", {
          code,
        });
        auth.login(data);
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  });
</script>
